var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["user-layout-wrapper", _vm.device], attrs: { id: "userLayout" } },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "top" }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "a-col",
                  [
                    _c("a-row", [
                      _c("img", {
                        staticClass: "logo",
                        attrs: {
                          src: require("@/assets/UD_" +
                            _vm.logoLanguage +
                            ".png"),
                          alt: "logo"
                        }
                      })
                    ]),
                    _c("a-row", [
                      _c(
                        "p",
                        {
                          staticClass: "mb-xs",
                          staticStyle: { "font-size": "2em" }
                        },
                        [_vm._v(_vm._s(_vm.$t("Content management system")))]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "main" }, [_c("router-view")], 1),
          _c("global-footer")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }